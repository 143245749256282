import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "dropdown", "search", "list", "country"]

  connect() {
    this.close = this.close.bind(this)
    // Only add click listener if we're in dropdown mode (button target exists)
    if (this.hasButtonTarget) {
      document.addEventListener("click", this.close)
    }
  }

  disconnect() {
    if (this.hasButtonTarget) {
      document.removeEventListener("click", this.close)
    }
  }

  toggle(event) {
    event.stopPropagation()
    this.dropdownTarget.classList.toggle("hidden")

    if (!this.dropdownTarget.classList.contains("hidden")) {
      this.searchTarget.focus()
      this.dropdownTarget.classList.add("animate__animated", "animate__fadeIn", "animate__faster")
    }
  }

  close(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add("hidden")
    }
  }

  filter() {
    const searchTerm = this.searchTarget.value.toLowerCase()

    this.countryTargets.forEach((country) => {
      const countryName = country.textContent.toLowerCase()
      const shouldShow = countryName.includes(searchTerm)

      // Handle both grid and list views
      if (country.closest('.grid')) {
        country.closest('.btn').classList.toggle("hidden", !shouldShow)
      } else {
        country.classList.toggle("hidden", !shouldShow)
      }
    })
  }

  select(event) {
    event.preventDefault()
    const link = event.currentTarget
    const countryCode = link.dataset.countryCode
    const currentHost = window.location.host

    let newHost
    if (countryCode === 'DK') {
      if (currentHost.includes('lvh.me')) {
        newHost = 'noupetit.dk.lvh.me:3000'
      } else {
        newHost = 'www.noupetit.dk'
      }
    } else {
      if (currentHost.includes('lvh.me')) {
        newHost = 'noupetit.com.lvh.me:3000'
      } else {
        newHost = 'www.noupetit.com'
      }
    }

    window.location.href = `${window.location.protocol}//${newHost}${window.location.pathname}?country_code=${countryCode}`
  }
}